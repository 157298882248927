<template>
  <div class="publish-product-done">
    <div class="publish-product-done-info-box">
        <img class="publish-product-done-info-icon" src="/images/home/my_center_publish_product_has_done_icon.png" alt="发布完成图标">
        <p class="publish-product-done-info-title-one">等待审核</p>
        <p class="publish-product-done-info-title-two">审核成功自动发布</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.publish-product-done{
    margin-top: 30px;
    padding-bottom: 30px;
    width: 1200px;
    height: 547px;
    background: #FFFFFF;
    border-radius: 8px;
    
    .publish-product-done-info-box{
        width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: default;
        .publish-product-done-info-icon{
            margin-top: 120px;
            width: 200px;
            height: 200px;
        }
        .publish-product-done-info-title-one .publish-product-done-info-title-two{
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 16px;
            color: #000000;
            line-height: 26px;
            text-align: right;
            font-style: normal;
        }
  
    }
}
</style>