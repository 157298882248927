<template>
  <div class="publish-product-form-box">
    <!-- 游戏图标和名称 -->
    <div class="publish-product-games-icon-and-name">
        <img class="publish-product-games-icon"  :src="currentSelectedGameItem.gameIcon" alt="游戏图标">
        <p class="publish-product-games-name">{{currentSelectedGameItem.gameName}}</p>
    </div>
    <!-- 表单 -->
    <div class="publish-product-games-form-type-box" v-for="(item,index) in gamesModeList" :key="index">
        <!-- 单选框 -->
        <publish-product-form-radio
        v-if="item.type==='radio'"
        :title="item.title"
        :aliasTitle="item.aliasTitle"
        :dataList="item.dataList"
        :isRequired="item.isRequired"
        ></publish-product-form-radio>
        <!-- 多选框 -->
        <publish-product-form-checkbox 
        v-if="item.type==='checkbox'" 
        :title="item.title" 
        :aliasTitle="item.aliasTitle"
        :dataList="item.dataList"
        :isRequired="item.isRequired"
        ></publish-product-form-checkbox>
        <!-- 输入框 -->
        <publish-product-form-input 
        v-if="item.type==='input'" 
        :title="item.title" 
        :aliasTitle="item.aliasTitle"
        :placeholderText="item.placeholderText"
        :isRequired="item.isRequired"
        :showUnit="item.showUnit"
        >
        <div class="publish-product-form-input-tips">
            <p class="publish-product-form-input-tips-text">{{ item.tips }}</p>
        </div>
        </publish-product-form-input>
        <!-- 文本域 -->
        <publish-product-form-textarea
        v-if="item.type==='textarea'"
        :title="item.title" 
        :aliasTitle="item.aliasTitle"
        :placeholderText="item.placeholderText"
        :isRequired="item.isRequired"
        ></publish-product-form-textarea>
        <publish-product-form-images-upload 
        v-if="item.type==='screenshot'" 
        :title="item.title" 
        :aliasTitle="item.aliasTitle"
        :isRequired="item.isRequired"
        ></publish-product-form-images-upload>
    </div>
    <!-- 操作按钮 -->
    <div class="publish-product-form-steps-btn">
        <div class="publish-product-form-go-back-btn" @click="clickGoBackBtnHandler()">
            <p class="publish-product-form-go-back-btn-text">上一步</p>
        </div>
        <div class="publish-product-form-go-next-btn" @click="clickGoNextBtnHandler()">
            <p class="publish-product-form-go-next-btn-text">下一步</p>
        </div>
    </div>
  </div>
</template>

<script>
import PublishProductFormRadio from './publish-product-form-radio.vue';
import PublishProductFormInput from './publish-product-form-input.vue';
import PublishProductFormCheckbox from './publish-product-form-checkbox.vue';
import PublishProductFormTextarea from './publish-product-form-textarea.vue';
import PublishProductFormImagesUpload from './publish-product-form-images-upload.vue';
import  {mapState,mapMutations}  from 'vuex';
export default {
    name:"PublishProductForm",
    components:{
        PublishProductFormRadio,
        PublishProductFormInput,
        PublishProductFormCheckbox,
        PublishProductFormTextarea,
        PublishProductFormImagesUpload
    },
    data(){
        return{
            gamesModeList:[
                {
                    type:'radio',
                    title:'游戏区服',
                    aliasTitle:'gameZone',
                    dataList:['安卓QQ','苹果QQ','安卓微信','苹果微信'],
                    isRequired:true 
                },
                {
                    type:'input',
                    title:'价格',
                    aliasTitle:'price',
                    placeholderText:'请输入价格',
                    isRequired:true,
                    showUnit:true,
                    tips:''
                },
                {
                    type:'checkbox',
                    title:'星元皮肤',
                    aliasTitle:'xingYuanSkin',
                    dataList:['金色仲夏夜','零号雷霆','幽冥之眼','时之愿境','幽冥火','时之思念','幻海之心','时之羁绊','未来之甲'],
                    isRequired:true 
                },
                {
                    type:'radio',
                    title:'实名情况',
                    aliasTitle:'kycStatus',
                    dataList:['可二次实名','不可二次实名'], 
                    isRequired:true 
                },
                {
                    type:'radio',
                    title:'截图方式',
                    aliasTitle:'screenshotType',
                    dataList:['天榜号官方','用户自己上传'],
                    isRequired:true 
                },
                {
                    type:'textarea',
                    title:'截图备注',
                    aliasTitle:'screenshotNote',
                    placeholderText:'请填写截图备注',
                    isRequired:true
                },
                {
                    type:'input',
                    title:'游戏账号',
                    aliasTitle:'gameAccount',
                    placeholderText:'请输入游戏账号',
                    isRequired:true,
                    showUnit:false,
                    tips:''
                },
                {
                    type:'input',
                    title:'营地号',
                    aliasTitle:'yingDiAccount',
                    placeholderText:'请输入营地号',
                    isRequired:true,
                    showUnit:false,
                    tips:'注意：请保持营地隐私打开，便于官方截图'
                },
                {
                    type:'input',
                    title:'贵族等级',
                    aliasTitle:'vipLevel',
                    placeholderText:'请输入贵族等级',
                    isRequired:true,
                    showUnit:false,
                    tips:''
                },
                {
                    type:'radio',
                    title:'账号来源',
                    aliasTitle:'accountFrom',
                    dataList:['原号主','购买自天榜号','购买自其它平台已满6个月','购买自其它平台未满6个月'],
                    isRequired:true 
                },
                {
                    type:'screenshot',
                    title:'商品截图',
                    aliasTitle:'productScreenshot',
                    isRequired:true
                },
            ]
        }
    },
    methods:{
        ...mapMutations('publishProduct',['setCurrentStepsIndex']),
        //返回上一步
        clickGoBackBtnHandler(){
            this.setCurrentStepsIndex(this.currentStepsIndex-1);
        },
        //前进一步
        clickGoNextBtnHandler(){
            this.setCurrentStepsIndex(this.currentStepsIndex+1);
        }
    }, 
    computed:{
        ...mapState({
            currentStepsIndex:state=>state.publishProduct.currentStepsIndex,
            currentSelectedGameItem:state=>state.publishProduct.currentSelectedGameItem,
        })
    }

}
</script>

<style lang="less" scoped>
.publish-product-form-box{
    margin-top: 30px;
    padding-bottom: 30px;
    width: 1200px;
    background: #FFFFFF;
    border-radius: 8px;
    .publish-product-games-icon-and-name{
        margin-left: 50px;
        width: 1100px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        .publish-product-games-icon{
            margin-top: 20px;
            width: 40px;
            height: 40px;
            border-radius: 10px;
        }
        .publish-product-games-name{
            margin-top: 20px;
            margin-left: 14px;
            height: 19px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 14px;
            color: #1A1A1A;
            line-height: 19px;
            text-align: left;
            font-style: normal;
        }
    }
    .publish-product-games-form-type-box{
        width: 1200px;
        background: #FFFFFF;
        .publish-product-form-input-tips{
            margin-left: 20px;
            height: 38px;
            max-width: 730px;
            .publish-product-form-input-tips-text{
                height: 38px;
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #F02233;
                line-height: 38px;
                text-align: left;
                font-style: normal;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space:nowrap;
            }
        }
    }
    .publish-product-form-steps-btn{
        width: 1200px;
        display: flex;
        justify-content: center;
        margin-top: 50px;
        .publish-product-form-go-back-btn{
            width: 160px;
            height: 50px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #E5E5E5;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
            .publish-product-form-go-back-btn-text{
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 16px;
                color: #555555;
                line-height: 16px;
                text-align: right;
                font-style: normal;
            }
        }
        .publish-product-form-go-next-btn{
            margin-left: 20px;
            width: 160px;
            height: 50px;
            background: #FFF7F7;
            border-radius: 8px;
            border: 1px solid #F02233;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
            .publish-product-form-go-next-btn-text{
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 16px;
                color: #F02233;
                line-height: 16px;
                text-align: right;
                font-style: normal;
            }
        }
    }
}
</style>