<template>
  <div class="publish-product-form-input">
    <div class="publish-product-form-input-icon-box">
        <p v-if="isRequired" class="publish-product-form-input-icon">*</p>
    </div>
    <p class="publish-product-form-input-title">{{title}}</p>
    <div class="publish-product-form-input-item-box">
        <input class="publish-product-form-input-item" type="text" :placeholder="placeholderText" :style="!showUnit?'width:200px;':''">
        <div v-if="showUnit" class="publish-product-form-input-item-unit-box">
            <p class="publish-product-form-input-item-unit-text">元</p>
        </div>
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
    name:"PublishProductFormInput",
    props:{
        title:{
            type:String,
            default:'',
            required:true
        },
        aliasTitle:{
            type:String,
            default:'',
            required:true
        },
        placeholderText:{
            type:String,
            default:'',
            required:true
        },
        isRequired:{
            type:Boolean,
            default:false,
            required:true
        },
        showUnit:{
            type:Boolean,
            default:false,
            required:true
        }
    },
    data(){
        return{
            
        }
    }
}
</script>

<style lang="less" scoped>
.publish-product-form-input{
    width: 1100px;
    margin-top: 30px;
    background: #FFFFFF;
    margin-left: 50px;
    display: flex;
    align-items: center;
    .publish-product-form-input-icon-box{
        width: 8px;
        height: 21px;
        .publish-product-form-input-icon{
            width: 8px;
            height: 21px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 15px;
            color: #E74335; 
            line-height: 21px;
            text-align: left;
            font-style: normal;
            cursor: default;
        }
    }
    .publish-product-form-input-title{
        width: 112px;
        margin-left: 6px;
        height: 19px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 14px;
        color: #131415;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space:nowrap;
        cursor: default;
    }
    .publish-product-form-input-item-box{
        max-width: 950px;
        height: 38px;
        margin-left: 24px;
        position: relative;
        display: flex;
        cursor: default;
        .publish-product-form-input-item{
            width: 234px;
            height: 38px;
            outline: none;
            border: 1px solid #E5E5E5;
            border-radius: 4px;
            padding-left: 14px;
            &::placeholder{
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #C5C5C5;
                line-height: 19px;
                text-align: left;
                font-style: normal;
            }
        }
        .publish-product-form-input-item-unit-box{
            position: absolute;
            top: 0px;
            right: 0px;
            width: 54px;
            height: 38px;
            display: flex;
            background: #E5E5E5;
            justify-content: center;
            align-items: center;
            border-left: 1px solid #E5E5E5;
            border-radius: 0px 4px 4px 0px;
            cursor: default;
            .publish-product-form-input-item-unit-text{
                height: 19px;
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color:#999999;
                line-height: 19px;
                text-align: left;
                font-style: normal;
            }
        }
    }
}
</style>