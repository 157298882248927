<template>
  <div class="publish-product-form-radio">
    <div class="publish-product-form-required-icon-box">
        <p v-if="isRequired" class="publish-product-form-required-icon">*</p>
    </div>
    <p class="publish-product-form-title">{{title}}</p>
    <div class="publish-product-form-item-box">
        <ul>
            <li v-for="(item,index) in dataList" :key="index" @click="clickRadioItemHandler(index)">
                <img class="publish-product-form-item-icon" :src="currentSelectItemIndex === index ? '/images/home/my_center_radio_has_done_icon.png' : '/images/home/my_center_radio_not_done_icon.png'" alt="选项图标" >
                <p class="publish-product-form-item-title">{{item}}</p>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
    name:"PublishProductRadioItem",
    props:{
        title:{
            type:String,
            default:'',
            required:true
        },
        aliasTitle:{
            type:String,
            default:'',
            required:true
        },
        dataList:{
            type:Array,
            default:()=>[],
            required:true
        },
        isRequired:{
            type:Boolean,
            default:false,
            required:true
        },
    },
    data(){
        return{
            radioItemData:{
                title:'游戏区服',
                allValue:['安卓QQ','苹果QQ','安卓微信','苹果微信','安卓QQ','苹果QQ','安卓微信','苹果微信','安卓QQ','苹果QQ','安卓微信','苹果微信']
            },
            currentSelectItemIndex:-1
        }
    },
    methods:{
        //用户点击项
        clickRadioItemHandler(index){
            this.currentSelectItemIndex = index;
        }
    }
}
</script>

<style lang="less" scoped>
.publish-product-form-radio{
    width: 1100px;
    background: #FFFFFF;
    margin-top: 10px;
    margin-left: 50px;
    display: flex;
    .publish-product-form-required-icon-box{
        margin-top: 20px;
        width: 8px;
        height: 21px;
        .publish-product-form-required-icon{
            width: 8px;
            height: 21px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 15px;
            color: #E74335;
            line-height: 21px;
            text-align: left;
            font-style: normal;
        }
    }

    .publish-product-form-title{
        margin-top: 20px;
        width: 112px;
        margin-left: 6px;
        height: 19px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 14px;
        color: #131415;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space:nowrap;
        cursor: default;
    }
    .publish-product-form-item-box{
        width: 950px;
        margin-left: 24px;
        ul{
            width: 950px;
            list-style-type: none;
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
            cursor: default;
            li{
                margin-right: 49px;
                margin-top: 20px;
                list-style: none;
                display: flex;
                max-width: 450px;
                .publish-product-form-item-icon{
                    width: 18px;
                    height: 18px;
                }
                .publish-product-form-item-title{
                    max-width: 420px;
                    margin-left: 10px;
                    height: 19px;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #131415;
                    line-height: 19px;
                    text-align: left;
                    font-style: normal;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space:nowrap;
                }
            }
        }
    }
}
</style>