<template>
  <div class="publish-product-setting">
    <!-- 游戏图标和名称 -->
    <div class="publish-product-games-icon-and-name">
        <img class="publish-product-games-icon"  :src="currentSelectedGameItem.gameIcon" alt="游戏图标">
        <p class="publish-product-games-name">{{currentSelectedGameItem.gameName}}</p>
    </div>
    <!-- 表单 -->
    <div class="publish-product-setting-form" v-for="(item,index) in formData" :key="index">
        <!-- 输入框 -->
        <publish-product-form-input 
        v-if="item.type==='input'" 
        :title="item.title" 
        :aliasTitle="item.aliasTitle"
        :placeholderText="item.placeholderText"
        :isRequired="item.isRequired"
        :showUnit="item.showUnit"
        >
        <div class="publish-product-form-input-tips">
            <p class="publish-product-form-input-tips-text">{{ item.tips }}</p>
        </div>
        </publish-product-form-input>
        <!-- 单选框 -->
        <publish-product-form-radio
        v-if="item.type==='radio'"
        :title="item.title"
        :aliasTitle="item.aliasTitle"
        :dataList="item.dataList"
        :isRequired="item.isRequired"
        ></publish-product-form-radio>
    </div>
    <!-- 操作按钮 -->
    <div class="publish-product-setting-btn-box">
        <div class="publish-product-setting-go-back-btn" @click="clickGoBackBtnHandler()">
            <p class="publish-product-setting-go-back-btn-text">上一步</p>
        </div>
        <div class="publish-product-setting-commit-btn" @click="clickCommitBtnHandler()">
            <p class="publish-product-setting-commit-btn-text">提交</p>
        </div>
    </div>
  </div>
</template>

<script>
import PublishProductFormInput from './publish-product-form-input.vue';
import PublishProductFormRadio from './publish-product-form-radio.vue';
import  {mapState,mapMutations}  from 'vuex';
export default {
    name:"PublishProductSetting",
    components:{
        PublishProductFormInput,
        PublishProductFormRadio
    },
    data(){
        return{
            formData:[
                {
                    type:'input',
                    title:'联系QQ',
                    aliasTitle:'price',
                    placeholderText:'请输入联系QQ',
                    isRequired:true,
                    showUnit:false,
                    tips:''
                },
                {
                    type:'input',
                    title:'联系微信',
                    aliasTitle:'price',
                    placeholderText:'请输入联系微信',
                    isRequired:true,
                    showUnit:false,
                    tips:''
                },
                {
                    type:'input',
                    title:'联系电话',
                    aliasTitle:'price',
                    placeholderText:'请输入联系电话',
                    isRequired:true,
                    showUnit:false,
                    tips:''
                },
                {
                    type:'radio',
                    title:'是否支持包赔',
                    aliasTitle:'asSupportIndemn',
                    dataList:['是','否'],
                    isRequired:true 
                },
                {
                    type:'radio',
                    title:'有无防沉迷',
                    aliasTitle:'asFcm',
                    dataList:['无防沉迷','有防沉迷'],
                    isRequired:true 
                },
                {
                    type:'radio',
                    title:'是否接受砍价',
                    aliasTitle:'asSupportBargain',
                    dataList:['是','否'],
                    isRequired:true 
                },
            ]
        }
    },
    methods:{
        ...mapMutations('publishProduct',['setCurrentStepsIndex']),
        //返回上一步
        clickGoBackBtnHandler(){
            this.setCurrentStepsIndex(this.currentStepsIndex-1);
        },
        //点击提交按钮
        clickCommitBtnHandler(){
            this.setCurrentStepsIndex(this.currentStepsIndex+1);
        }
    },
    computed:{
        ...mapState({
            currentStepsIndex:state=>state.publishProduct.currentStepsIndex,
            currentSelectedGameItem:state=>state.publishProduct.currentSelectedGameItem
        })
    },
    mounted(){
        
    }
}
</script>

<style lang="less" scoped>
.publish-product-setting{
    margin-top: 30px;
    padding-bottom: 30px;
    width: 1200px;
    background: #FFFFFF;
    border-radius: 8px;
    .publish-product-games-icon-and-name{
        margin-left: 50px;
        width: 1100px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        .publish-product-games-icon{
            margin-top: 20px;
            width: 40px;
            height: 40px;
            border-radius: 10px;
        }
        .publish-product-games-name{
            margin-top: 20px;
            margin-left: 14px;
            height: 19px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 14px;
            color: #1A1A1A;
            line-height: 19px;
            text-align: left;
            font-style: normal;
        }
    }
    .publish-product-setting-form{
        width: 1200px;
        background: #FFFFFF;
    }
    .publish-product-setting-btn-box{
        margin-top: 50px;
        width: 1200px;
        display: flex;
        justify-content: center;
        .publish-product-setting-go-back-btn{
            width: 160px;
            height: 50px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #E5E5E5;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
            .publish-product-setting-go-back-btn-text{
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 16px;
                color: #555555;
                line-height: 16px;
                text-align: right;
                font-style: normal;
            }
        }
        .publish-product-setting-commit-btn{
            margin-left: 20px;
            width: 160px;
            height: 50px;
            background: #F02233;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
            .publish-product-setting-commit-btn-text{
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 16px;
                text-align: right;
                font-style: normal;
            }
        }
    }
}
</style>